import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
const RowWrapper: FC<{ children: ReactNode; direction: boolean }> = ({ children, direction }) => {
  return (
    <div
      className={clsx(
        direction ? 'xl:flex-row' : 'xl:flex-row-reverse',
        'flex flex-col items-center justify-between',
      )}
    >
      {children}
    </div>
  );
};

export default RowWrapper;
