import React, { useEffect } from 'react';
import './App.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import FreshYield from './components/FreshYield';
import ImageWithFrame from './components/ImageWithFrame';
import RowWrapper from './components/RowWrapper';
import clsx from 'clsx';

gsap.registerPlugin(ScrollTrigger);

export default function App() {
  useEffect(() => {
    const duration = 0.5;

    ['.q1', '.q2', '.q3', '.q4'].forEach(quarter => {
      gsap.fromTo(
        quarter,
        { autoAlpha: 0, y: 20 },
        {
          duration,
          scrollTrigger: {
            trigger: quarter,
          },
          autoAlpha: 1,
          y: 0,
        },
      );
    }, []);
  });

  return (
    <>
      <div className="h-full w-full">
        <div className="absolute -z-10 grid h-full w-full grid-cols-5 divide-x bg-[#f1f0ec]">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="flex justify-center">
          <div className="mx-2 mt-4 flex w-full flex-row items-center justify-between md:mx-12">
            <div className="text-black">
              {' '}
              <img
                className="w-1/3 rounded-3xl bg-[#3b1940] p-2"
                src="images/citrusLogoMediumAllWhite2.svg"
              />
            </div>
            <a href="https://www.app.citrus.so/">
              <div className="from-orange-400 hover:from-orange-300 whitespace-nowrap rounded-full bg-gradient-to-br to-rose-400 px-5 py-2 text-sm font-semibold text-white shadow-lg transition ease-in-out hover:scale-110	hover:shadow-xl md:text-base">
                Launch App
              </div>
            </a>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-row justify-between pl-8">
            <div className="flex flex-col">
              <div className="w-full md:w-[500px]">
                <FreshYield />
                <div className="toApp my-8 text-xl">
                  Citrus is the primary lending and borrowing protocol for cross-chain assets on
                  Solana
                </div>
              </div>
              <a href="https://www.app.citrus.so/" className="self-start">
                <div className="toApp from-orange-400 hover:from-orange-300 mb-20 self-start whitespace-nowrap rounded-full bg-gradient-to-br to-rose-400 px-5 py-2 text-sm font-semibold text-white shadow-lg transition-transform	 ease-in-out hover:scale-110	hover:shadow-xl md:text-base">
                  Get Started
                </div>
              </a>
            </div>
            <div className="w-600 mt-40 ml-96 hidden xl:inline">
              {' '}
              <img
                className="toApp w-600"
                alt="woman gardening illustration"
                src="images/garden.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-stone-200 h-px w-full" />
      <div className="flex min-h-screen justify-center bg-[#f1f0ec] xl:pt-36">
        <div className="mx-4 flex max-w-[1400px] flex-col">
          <RowWrapper direction={true}>
            <div className="max-w-xl -rotate-3 rounded-3xl bg-white p-8 shadow-xl transition-all xl:mb-0 xl:mr-20 ">
              <div className="flex flex-col divide-y">
                <p className="text-stone-900 pb-2 text-3xl xl:text-4xl">Citrus Exchange Launch</p>
                <p className="text-stone-400 py-2 text-lg">2021 Q4</p>
                <p className="text-stone-500 py-2 text-lg">
                  Citrus introduces a novel method for transferring native assets from $ETH $AVAX
                  $LUNA $BNB and $MATIC to native Solana without the use of a centralized exchange.
                  Use of Wormhole and Serum guarentees quality transaction throughput and
                  competitive rates.
                </p>
                <div className="flex w-full flex-row">
                  <a className="self-start pt-2" href="https://www.app.citrus.so/exchange">
                    <div className="from-orange-400 hover:from-orange-300 rounded-full bg-gradient-to-br to-rose-400 px-5 py-2 font-semibold text-white shadow-2xl shadow-lg">
                      Check It Out
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <ImageWithFrame tiltLeft={true} src="images/citrus-painting.png" greenBorder={true} />
          </RowWrapper>

          <RowWrapper direction={false}>
            <div className="max-w-xl rotate-3 rounded-3xl bg-white p-8 shadow-xl transition-all">
              <div className="flex flex-col divide-y">
                <p className="text-stone-900 pb-2 text-3xl xl:text-4xl">
                  Citrus Lending Testing Release
                </p>
                <p className="text-stone-400 py-2 text-lg">2022 Q1</p>
                <p className="text-stone-500 py-2 text-lg">
                  Citrus launches its testing release of its lending and borrowing protocol,
                  becoming the first protocol to provide utility and yield to wormhole wrapped
                  assets on Solana. Confidently put your wormhole assets to use on Citrus.
                </p>
                <div>
                  <div className="flex w-full flex-row">
                    <a className="self-start pt-2" href="https://www.app.citrus.so/">
                      <div
                        className={clsx(
                          'from-orange-400 hover:from-orange-300 rounded-full bg-gradient-to-br to-rose-400',
                          'px-5 py-2 font-semibold text-white shadow-2xl shadow-lg',
                        )}
                      >
                        Start Now
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ImageWithFrame tiltLeft={false} src="images/lime.jpg" greenBorder={false} />
          </RowWrapper>

          <RowWrapper direction={true}>
            <div className="max-w-xl -rotate-3 rounded-3xl bg-white p-8 shadow-xl transition-all">
              <div className="flex flex-col divide-y">
                <p className="text-stone-900 pb-2 text-3xl xl:text-4xl">Full Cross-Chain Lending</p>
                <p className="text-stone-400 py-2 text-lg">2022 Q2</p>
                <p className="text-stone-500 pt-2 text-lg">
                  Citrus uses wormhole to become the first fully cross-chain lending and borrowing
                  protocol. Users will be able to deposit assets natively from Ethereum, Luna, and
                  more. Seamlessly integrate with Metamask wallet and integrate with Citrus's
                  ecosystem.
                </p>
              </div>
            </div>
            <ImageWithFrame tiltLeft={true} src="images/peach.png" greenBorder={true} />
          </RowWrapper>

          <RowWrapper direction={false}>
            <div className="max-w-xl rotate-3 rounded-3xl bg-white p-8 shadow-xl transition-all ">
              <div className="flex flex-col divide-y">
                <p className="text-stone-900 pb-2  text-3xl xl:text-4xl">Citrus Token Launch</p>
                <p className="text-stone-400 py-2 text-lg">2022 Q3</p>
                <p className="text-stone-500 pt-2 text-lg">
                  <span className="text-stone-900 font-bold">$Citrus</span> token IDO, integrating
                  and collaborating with other leading projects on the Solana ecosystem.
                </p>
              </div>
            </div>
            <ImageWithFrame tiltLeft={false} src="images/lemon.jpg" greenBorder={false} />
          </RowWrapper>
          <div className="text-stone-800 pt-36 text-center text-4xl font-semibold">
            Get Started Today
          </div>
          <div className="box flex flex-row justify-center pb-36 pt-10">
            <div className="pr-20">
              {' '}
              <div className="from-orange-400 hover:from-orange-300 mt-4 whitespace-nowrap rounded-full bg-gradient-to-br to-rose-400 px-5 py-2 text-sm font-semibold text-white shadow-2xl shadow-lg transition-all hover:scale-110 md:text-base">
                <a href="https://www.app.citrus.so/">Launch App</a>
              </div>
            </div>
            <div>
              <div className="border-stone-800 mt-4 whitespace-nowrap rounded-full bg-[#5bb9ac] px-5 py-2 text-sm font-semibold text-white shadow-lg transition-all hover:scale-110 hover:shadow-xl md:text-base">
                <a href="https://discord.gg/gJJyxzpyaA" target="_blank">
                  Join Discord
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
