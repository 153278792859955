import anime from 'animejs';
import React, { useEffect } from 'react';

import './freshYield.css';

export default function FreshYield() {
  useEffect(() => {
    anime
      .timeline({ loop: false })
      // .add({
      //   targets: '.startTitle',
      //   opacity: [0, 1],
      //   easing: 'easeOutExpo',
      //   duration: 200,
      // })
      .add({
        targets: '.ml5 .line',
        opacity: [0.5, 1],
        scaleX: [0, 1],
        easing: 'easeInOutExpo',
        duration: 700,
      })
      .add({
        targets: '.ml5 .line',
        duration: 600,
        easing: 'easeOutExpo',
        // @ts-ignore
        translateY: (el, i) => -0.625 + 0.625 * 2 * i + 'em',
      })
      // .add({
      //   targets: '.ml5 .ampersand',
      //   opacity: [0, 1],
      //   scaleY: [0.5, 1],
      //   easing: 'easeOutExpo',
      //   duration: 600,
      //   offset: '-=600',
      // })
      .add({
        targets: '.ml5 .letters-left',
        opacity: [0, 1],
        translateX: ['0.5em', 0],
        easing: 'easeOutExpo',
        duration: 600,
        offset: '-=300',
      })
      .add({
        targets: '.ml5 .letters-right',
        opacity: [0, 1],
        translateX: ['-0.5em', 0],
        easing: 'easeOutExpo',
        duration: 600,
        offset: '-=600',
      })
      .add({
        targets: '.subtitle',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 600,
      })
      .add({
        targets: '.toApp',
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 600,
      });
  });
  return (
    <div className="">
      <div className="z-20 flex flex-col xl:flex-row">
        <div className="font-fatface flex  flex-col text-5xl xl:text-8xl">
          <h1 className="ml5 pt-20 text-[#3b1940] text-slate-900">
            <div className="startTitle text-[#3b1940]">Providing</div>
            <span className="text-wrapper">
              <span className="line line1"></span>
              <span className="letters letters-left pr-2.5 text-[#5bb9ac]">Fresh</span>
              {/* <span className="letters ampersand px-1"> &amp; </span> */}
              <span className="letters letters-right text-[#5bb9ac]">Yield</span>
              <span className="line line2"></span>
            </span>
          </h1>
          <span className="subtitle text-[#3b1940]">Every Day</span>
        </div>
        {/* <div className="subtitle ml-20 mt-20 w-96 pt-20">
        <img src="/images/citrus-painting.png" />
      </div> */}
      </div>
    </div>
  );
}
