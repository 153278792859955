import React from 'react';
import clsx from 'clsx';
interface ImageWithFrameProps {
  greenBorder: boolean;
  tiltLeft: boolean;
  src: string;
}
function ImageWithFrame({ src, greenBorder, tiltLeft }: ImageWithFrameProps) {
  return (
    <div className="items-center justify-center py-12">
      <div className="flex justify-center">
        <div
          className={clsx(
            greenBorder
              ? 'from-green-400 via-teal-500 bg-gradient-to-br to-[#5bb9ac]'
              : 'from-orange-400 bg-gradient-to-br to-rose-400 ',
            tiltLeft ? 'rotate-3 hover:rotate-6' : '-rotate-3 hover:-rotate-6',
            'w-full rounded-xl p-8 shadow-2xl transition-all',
          )}
        >
          <img
            alt="classical painting of some oranges"
            className="bg-stone-100 h-[200px] w-[200px] rounded-3xl object-cover sm:h-[350px] sm:w-[350px]"
            src={src}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageWithFrame;
